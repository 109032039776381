/* Primary Styles */
*, *::before, *::after {
  box-sizing: border-box;
}

html, body, #container, #container > div, #container > div > div {
  overflow-y: auto;
  height: 100%;
}

.pt-overlay-scroll-container .pt-dialog-body{
  font-size: 13px;
}

.s-layout, .s-layout__content {
  min-height: 100%;
}
.chapter-content{
  font-size: 13px;
}

.chapter-content .screen h3{
  font-size: 16px;
}

.chapter-content p{
  font-size: 13px;
}

.pt-tree-root{
  font-size: 13px;
}

.pt-button-text{
  font-size: 13px;
}

.pt-navbar-group.pt-align-center{
  font-size: 13px;
}

.chapter-content > .content {
  height: -moz-calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
  height: -o-calc(100% - 65px);
  height: calc(100% - 65px);
}

/* Layout */
.s-layout {
  display: flex;
  width: 100%;
  padding-top: 50px;
}

.s-layout__content {
  display: flex;
  flex: 1;
  vertical-align: top;
  padding: 15px 25px;
  transition: all .3s ease-in;
}

/* Sidebar */
.s-sidebar__nav {
  position: fixed;
  top: 50px;
  right: 0em;
  transition: all .3s ease-in;
  width: 28em;
  background: #2e3336;
  color: rgba(255, 255, 255, 0.7);
  padding: 15px;
  overflow-y: auto;
  height: 100%;
  z-index: 7;
}

.s-sidebar__toggled {
  right: -28em;
}

/* Mobile First */
@media (min-width: 68em) {
  .s-layout__content {
     margin-right: 28em;
     margin-top: 0px;
  }
  
  /* Sidebar */
  .s-sidebar__nav {
     width: 28em;
     right: 0;
     top: 50px;
  }

  .s-content__toggled {
    margin-right: 0em;
  }

  .s-sidebar__toggled {
    right: -28em;
  }

  .return-to-dashboard {
    margin-right: 0px !important;
  }

  .navbar-mobile {
    display: none;
  }

}

@media (max-width: 30em) {
  .s-layout {
    margin-top: 85px;
    padding: 0px;
  }

  .s-layout > .s-layout__content {
    padding-right: 0px;
    padding-left: 0px;
  }

  .s-layout > .s-layout__content > .chapter-content > .content {
    box-shadow: none;
    border: none;
    margin-bottom: 35px;
  }

  .fact-icon {
    margin-bottom: -30px !important;
  }

  .pt-tree-node-secondary-label {
    display: none;
  }

  .s-sidebar__nav {
    width: 100%;
    right: -0em;
    top: 99px;
 }

  .s-sidebar__toggled {
    right: -100%;
  }

  .navbar-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .navbar-main .return-to-dashboard, .navbar-main .sidebar-toggle {
    display: none;
  }

  .navbar-mobile {
    top: 49px !important;
  }

  .pt-tab-list {
    font-size: 13px;
  }

  .pt-tab-list .pt-tree{
    font-size: 13px;
  }

  .question-navigation.pt-dark {
    position: fixed;
    bottom: 0;
    margin-bottom: 0px !important;
    background-color: #394b59 !important;
    color: #fff !important;
  }
}