@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
.console-wrap {
    width: 100%;
    margin: 0px auto 0 auto;
    padding: 10px 7px;
    background: #1d2127;
}
  
.console-body {
    margin: 0;
    background: #1d2127;
    color: rgb(38, 188, 89);
    font: 1.0em 'Inconsolata', Consolas, 'Courier New';
    width: 100%;
    line-height: 1.6em;
  
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    border: 1px solid rgba(138, 155, 168, 0.2);
}

.console-body .output {
    border: none;
    margin: 0;
    background: #1d2127;
    color: rgb(38, 188, 89);

    font-weight: bolder;
    font-size: 14px;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
}
/* Primary Styles */
*, *::before, *::after {
  box-sizing: border-box;
}

html, body, #container, #container > div, #container > div > div {
  overflow-y: auto;
  height: 100%;
}

.pt-overlay-scroll-container .pt-dialog-body{
  font-size: 13px;
}

.s-layout, .s-layout__content {
  min-height: 100%;
}
.chapter-content{
  font-size: 13px;
}

.chapter-content .screen h3{
  font-size: 16px;
}

.chapter-content p{
  font-size: 13px;
}

.pt-tree-root{
  font-size: 13px;
}

.pt-button-text{
  font-size: 13px;
}

.pt-navbar-group.pt-align-center{
  font-size: 13px;
}

.chapter-content > .content {
  height: -o-calc(100% - 65px);
  height: calc(100% - 65px);
}

/* Layout */
.s-layout {
  display: flex;
  width: 100%;
  padding-top: 50px;
}

.s-layout__content {
  display: flex;
  flex: 1 1;
  vertical-align: top;
  padding: 15px 25px;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

/* Sidebar */
.s-sidebar__nav {
  position: fixed;
  top: 50px;
  right: 0em;
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  width: 28em;
  background: #2e3336;
  color: rgba(255, 255, 255, 0.7);
  padding: 15px;
  overflow-y: auto;
  height: 100%;
  z-index: 7;
}

.s-sidebar__toggled {
  right: -28em;
}

/* Mobile First */
@media (min-width: 68em) {
  .s-layout__content {
     margin-right: 28em;
     margin-top: 0px;
  }
  
  /* Sidebar */
  .s-sidebar__nav {
     width: 28em;
     right: 0;
     top: 50px;
  }

  .s-content__toggled {
    margin-right: 0em;
  }

  .s-sidebar__toggled {
    right: -28em;
  }

  .return-to-dashboard {
    margin-right: 0px !important;
  }

  .navbar-mobile {
    display: none;
  }

}

@media (max-width: 30em) {
  .s-layout {
    margin-top: 85px;
    padding: 0px;
  }

  .s-layout > .s-layout__content {
    padding-right: 0px;
    padding-left: 0px;
  }

  .s-layout > .s-layout__content > .chapter-content > .content {
    box-shadow: none;
    border: none;
    margin-bottom: 35px;
  }

  .fact-icon {
    margin-bottom: -30px !important;
  }

  .pt-tree-node-secondary-label {
    display: none;
  }

  .s-sidebar__nav {
    width: 100%;
    right: -0em;
    top: 99px;
 }

  .s-sidebar__toggled {
    right: -100%;
  }

  .navbar-wrapper {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .navbar-main .return-to-dashboard, .navbar-main .sidebar-toggle {
    display: none;
  }

  .navbar-mobile {
    top: 49px !important;
  }

  .pt-tab-list {
    font-size: 13px;
  }

  .pt-tab-list .pt-tree{
    font-size: 13px;
  }

  .question-navigation.pt-dark {
    position: fixed;
    bottom: 0;
    margin-bottom: 0px !important;
    background-color: #394b59 !important;
    color: #fff !important;
  }
}
body {
  background: #ecedf0;
  font-family: "Open Sans", Arial, sans-serif;
}

div.question-block {
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  padding-left: 15px;
}

div p {
  line-height: 1.5em;
}

h2 {
  line-height: 1em;
}

code {
  background: #f7f7f9;
  padding: 1px 5px;
  color: #137cbd;
  font-size: 14px;
  box-shadow: none !important;
}

svg {
  pointer-events: none;
}

button {
  margin-bottom: 5px;
  box-shadow: none !important;
}

.icon-check {
  margin-left: -18px;
}

.copy-code {
  margin-left: 4px;
}

.pull-right {
  float: right;
}

.clear {
  clear: both;
}

.editor {
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.ace_scrollbar-inner {
  background-color: #fff;
  opacity: 0.01;
}

.ace_scrollbar-inner.ace_dark {
  background-color: #000;
}

/* Navbar */
.pt-navbar-group {
  height: 38px;
}

.navbar-wrapper {
  margin: 8px 10px;
}

/* Chapter */
.chapter-content {
  width: 100%;
}

.chapter-content > .content {
  padding-bottom: 70px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.4);
  min-height:calc(100% - 65px);
  height:auto;
}

.screen h2 {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 600;
}

.screen h3 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.screen .action-buttons {
  margin-top: 10px;
}

/* Questions */
.pt-control.pt-large .pt-control-indicator {
  background: #efefef;
}

label.pt-label {
  font-size: 16px;
  padding: 15px 0px;
  margin-bottom: 0px;
  margin-top: 15px;
  font-weight: 500;
}

.pt-control {
  margin-bottom: 0px !important;
  padding: 14px !important;
  -webkit-transition: background-color ease-in 100ms;
  transition: background-color ease-in 100ms;
}

label.pt-control p {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  font-family: 'Varela Round', sans-serif;
}

label.pt-control {
  border: 2px solid #f6f6f6;
  font-weight: normal;
  margin-top: -2px;
}

label.pt-control:hover {
  background: #f6f6f6;
  box-shadow: 0 4px 4px rgba(0,0,0,.16), 0 4px 4px rgba(0,0,0,.23);
  z-index: 1;
}

label.pt-control.selected {
  background: #137cbd;
  border-color: #137cbd;
  color: #fff;
  box-shadow: 0 4px 4px rgba(0,0,0,.16), 0 4px 4px rgba(0,0,0,.23);
  z-index: 1;
}

label.pt-control.selected code {
  background: rgba(255, 255, 255, 0.7);
  color: #5c7080;
}

label.pt-control.selected:hover {
  background: #2477aa;
  border-color: #2477aa;
}

label.pt-control.selected a {
  background: #137cbd;
  border-color: #137cbd;
  color: #fff;
}

label.pt-control.selected:hover a {
  background: #2477aa;
  border-color: #2477aa;
}

label.pt-control.wrong-answer {
  background: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.pt-control.pt-large {
  font-size: 15px;
}

/* retake lesson */

.retake-button {
  background: #30404d;
  color: #fff;
  font-weight: 700;
}

.retake-button:hover {
  background: #4c6478;
}

.retake-alert {
  background: #ededed;
}

/* Show Answers */

.show-btn-answer {
  margin-right: 5px;
}
.show-answer {
  border-color: #21a16c !important;
  z-index: 1;
}
.show-answer:hover {
  border-color: #218b5f !important;
  z-index: 1;
}

.show-answer-pop-over p {
  font-size: 12px;
  color: #666;
}

/* Sidebar */

/* tabs */
.pt-tabs {
  margin: 0 auto;
}

.pt-elevation-0 {
  margin-bottom: 50px;
}

.pt-dark .pt-tab[aria-selected="true"] {
  box-shadow: inset 0 -3px 0 #cf2227 !important;
}

.pt-dark .pt-tab[aria-selected="true"],
.pt-dark .pt-tab:not([aria-disabled="true"]):hover {
  color: #f5f5f5 !important;
}

/* tree */
.pt-tree-node-expanded {
  background: #1e2224;
}

.pt-dark .pt-tree-node.pt-tree-node-selected > .pt-tree-node-content {
  background-color: #cf2227 !important;
}

/* hide sidebar child nodes */
.pt-tree-node-content-1,
.pt-tree-node-caret {
  visibility: hidden !important;
}

/* un-used caret */
.pt-tree-node-caret, .pt-tree-node-caret-none, .pt-tree-node-caret-closed {
  display: none;
}

.pt-tree-node-icon {
  margin-left: 10px;
}

.pt-tree-node.pt-tree-node-selected.done  > .pt-tree-node-content{
  color: #fff !important;
  background-color: #0f9960 !important;
}

.pt-tree-node.pt-tree-node-selected.done svg {
  color: #fff !important;
}

.pt-tree-node.done svg {
  color: #0f9960 !important;
}

/* Facts */
.fact-icon {
  text-align: right;
  margin-bottom: -20px;
}

.pt-transition-container {
  z-index: 1;
}

.pt-dialog {
  margin: 20px 30px;
}

.pt-dialog-body h3 {
  display: none;
}

/* Question Navigation */
.pt-fixed-bottom {
  position: relative !important;
}

.question-navigation {
  width: 100%;
  height: 50px;
  padding: 7px;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 0 0px !important;
  z-index: 2;
  background-color: #ecedf0 !important;
  color: #000 !important;
}

.question-navigation-popover > .pt-transition-container  {
  z-index: 2;
}

.pt-navbar-group.pt-align-center {
  justify-content: center;
}

.numbtn {
  width: 35px !important;
  text-align: center;
}

.pt-intent-success {
  font-weight: bold;
}

.pt-tag-unanswered {
  background-color: #30404d !important;
  color: #fff !important;
  font-weight: bold;
}

.selected-question {
  background: #5c7080 !important;
  font-weight: bold;
}

.pt-intent-success.selected-question {
  background: rgba(15,153,96,.15) !important;
  color: #3dcc91 !important;
}

.active-question {
  color: #fefefe !important;
}

.next-button, .start-button, .end-button, .prev-button {
  display: none;
}

.active-fact[class*="pt-icon-"]::before {
  color: #d9822b;
}

/* Buttons */
.repl-actions {
  text-align: right;
}

.repl-actions button {
  margin-top: 5px !important;
  margin-right: 7px !important;
}

.repl-actions .run-btn-tag {
  margin-top: 10px;
  margin-right: 5px;
  color: #999 !important;
  display: grid;
}

.selection-actions .pt-popover-target{
  overflow: hidden;
}

.cr-actions {
  text-align: right;
}

.cr-actions .pt-popover-target{
  overflow: hidden;
}

.cr-actions button {
  margin-top: 10px !important;
  margin-right: 5px;
}

.cr-actions .run-btn-tag {
  margin-top: 15px;
  margin-right: 5px;
  color: #999 !important;
  display: grid;
}

.cr-actions .check-btn-tag  {
  color: #999 !important;
  display: grid;
}

.output-label {
  background: rgba(138, 155, 168, 0.2) !important;
  color: #999 !important;
  margin-top: 10px;
  border-radius: 0;
}

.sidebar-toggle {
  margin-left: 10px;
}

button:focus {outline:0;}

/* Choices Indicator */
.pt-control {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pt-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.pt-control div {
  margin-left: 30px;
}

.pt-control-indicator {
  position: absolute;
  top: 14px !important;
  left: 14px !important;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: none !important;
  background-image: none !important;
}

.pt-control:hover input ~ .pt-control-indicator {
  background-color: #ccc;
}

.pt-control input:checked ~ .pt-control-indicator {
  background-color: #2196F3;
}

.pt-control-indicator:before {
  content: "";
  position: absolute;
  display: none;
}

.pt-control input:checked ~ .pt-control-indicator:before {
  display: block;
}

/* Tips */
.tips {
  margin-top: 25px;
}

/* Editor Tabs */
#editorTabs {
  background: #1d2127;
}

#editorTabs .pt-tab {
  padding: 5px 15px;
  color: #999;
  font-size: 13px;
  border-right: 1px solid #171717;
  outline: none;
}

#editorTabs .pt-tab[aria-selected="true"] {
  background: #171717;
  color: #106ba3 !important;
}

#editorTabs .pt-tab-indicator-wrapper {
  z-index: 1;
}

#editorTabs .pt-tab-list > *:not(:last-child) {
  margin-right: 0px;
}

#editorTabs .pt-tab-panel {
  margin-top: 0px;
}

#editorTabs #brace-editor {
  z-index: 1;
}

/* Output Display */
.repl-wrapper, .cr-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.editor-wrapper {
  width: 100%;
}

.display-wrap {
  width: 100%;
  display: inline-block;
  background: #1d2127;
}

.display-wrap-half {
  width: 50%;
  display: inline-block;
  background: #1d2127;
}

.sql-table-wrap {
  height: 300px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.browser-display-wrap {
  vertical-align: top;
  display: inline-block;
  border: 2px solid #e9e9e9;
  height: 300px;
  width: 100%;
  overflow: hidden;
}

.browser-display-wrap iframe.browser-display-output {
  width: 100%;
  height: 256px;
  overflow: scroll;
  display: block;
}

.frame-options iframe.browser-display-output {
	width: calc(100% - 30px);
	height: 256px;
	overflow: scroll;
	display: block;
}

.browser-display-wrap-half {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 50%;
  display: inline-block;
  border: 2px solid #e9e9e9;
  overflow: hidden
}

.browser-display-wrap-half iframe.browser-display-output {
    position: absolute;
    top: 40px;
    width: 100%;
    height: calc(100% - 40px);
    overflow: scroll;
    display: block;
}

div.browser-display-output {
  overflow: hidden;
}

.browser-display-tabs {
    float: left;
    width: 100%;
    background: #e9e9e9;
    height: 40px;
}

.browser-display-tabs .pt-tab {
  padding: 5px 15px;
  color: #999;
  font-size: 13px;
  border-right: 1px solid #f0f0f0;
  outline: none;
}

.browser-display-tabs .pt-tab:hover, .browser-display-tabs .pt-tab:focus {
  color: #999;
}

.browser-display-tabs .pt-tab[aria-selected="true"] {
    background: #fff;
    box-shadow: none;
}

.browser-display-tabs .pt-tab-list > *:not(:last-child) {
    margin-right: 0px;
}

.browser-display-tabs .pt-dark .pt-button:not([class*="pt-intent-"]) {
    background: #1d2127;
    color: #999;
}

.browser-display-tabs .pt-dark .pt-active {
    background: #171717 !important;
}

.browser-display-tabs .pt-tab-indicator-wrapper .pt-tab-indicator {
    background: none;
}

.browser-console-output code {
  color: #FF0000;
  font-size: 12px;
}

.browser-display-tabs .pt-tab-panel {
  margin-top: 0px;
}

@-moz-document url-prefix() {
  .browser-display-output {
    height: 17.6em;
  }
}

.browser-display-top-bar {
  background: #1d2127;
  height: 40px;
}

#browser-display-address-bar {
  width: 60%;
  background: #1d2127;
  height: 40px;
  color: #888;
  margin-left: 5px;
  float: left;
}

#browser-display-address-bar:focus {
  outline: none;
  box-shadow: none;
}

#browser-display-controls {
  float: right;
  height: 40px;
  background: #171717;
  width: 40px;
}

.browser-toggle-btn {
  margin: 5px !important;
  float: right;
}

.browser-toggle-btn:hover {
  background: transparent !important;
}

.browser-toggle-btn:hover > svg {
  color: #106ba3 !important;
}

.frame-options {
  margin-left: 30px;
  width: 100%;
}

.show-answer {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word; 
}
