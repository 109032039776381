.console-wrap {
    width: 100%;
    margin: 0px auto 0 auto;
    padding: 10px 7px;
    background: #1d2127;
}
  
.console-body {
    margin: 0;
    background: #1d2127;
    color: rgb(38, 188, 89);
    font: 1.0em 'Inconsolata', Consolas, 'Courier New';
    width: 100%;
    line-height: 1.6em;
  
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    border: 1px solid rgba(138, 155, 168, 0.2);
}

.console-body .output {
    border: none;
    margin: 0;
    background: #1d2127;
    color: rgb(38, 188, 89);

    font-weight: bolder;
    font-size: 14px;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
}